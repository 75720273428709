<template>
  <v-container fluid>
    <v-row class="d-flex justify-content-center">
      <v-col cols="12" xl="6" lg="7" md="10" class="p-0">
        <v-card :flat="$vuetify.breakpoint.mobile" class="mt-5" style="max-height: 700px; overflow-y: auto; overflow-x: hidden" 
                @scroll="delayedscroll()">
          <v-container fluid>
            <v-row v-if="rows.length == 0">
              <v-col>
                <v-card-text class="text-center">
                  <v-icon size="100" color="grey lighten-2">mdi-account-group-outline</v-icon>
                  <h3 class="mt-3">Henüz içerik eklenmemiş.</h3>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row v-else class="mt-3">
              <v-col class="p-0" cols="12" v-for="row in rows" :key="row.Id">
                <v-sheet class="p-4" shaped min-height="200">
                  <v-row>
                    <v-col cols="auto" class="pt-2">
                      <v-avatar size="32" rounded>
                        <v-img v-if="row.PersonnelImage != null" :src="row.PersonnelImage" contain></v-img>
                        <v-icon v-else large>mdi-account-circle</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.mobile ? '10' : 'auto grow'">
                      <v-row>
                        <v-col cols="10" class="pb-0 pt-2 px-0">
                          <span class="font-weight-bold text-break" style="font-size: 14px">{{ row.Content.Title }}</span>
                        </v-col>
                        <v-col cols="2" class="py-0 d-flex justify-content-end">
                          <v-icon v-if="row.Content.ContentTypeId != 4" @click="downloadAll(row.Content.ContentFiles)"
                                  color="primary" :disabled="saving">mdi-cloud-download-outline
                          </v-icon>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pl-0">
                          <span class="text-muted"> {{ row.Content.CreatedBy }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="row.Content.Description && row.Content.ContentTypeId != 4">
                    <v-col cols="12" class="pt-0">
                      <v-icon small class="mr-1">mdi-text</v-icon>
                      <span style="font-size: 13px"> {{ row.Content.Description }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="p-0">
                      <v-img v-if="row.Content.ContentTypeId == 1 && row.Content.ContentFiles.length > 0" @click.stop="openUrl(row.Content.ContentFiles[0].FilePublicUrl)"
                             :src="row.Content.ContentFiles[0].FilePublicUrl" :max-height="$vuetify.breakpoint.mobile ? 320: 550"></v-img>

                      <video v-else-if="row.Content.ContentTypeId == 2 && row.Content.ContentFiles.length > 0" controls="" name="media" style="max-height: 400px">
                        <source :src="row.Content.ContentFiles[0].FilePublicUrl + '#t=0.1'" :type="row.Content.ContentFiles[0].FileType">
                      </video>

                      <b-carousel v-else-if="row.Content.ContentTypeId == 3" controls indicators :interval="0">
                        <b-carousel-slide v-for="item in row.Content.ContentFiles.filter(s => s.FileType.includes('image'))" :key="item.Id"
                                         :img-src="item.FilePublicUrl" @click.stop="openUrl(item.FilePublicUrl)">
                          <!--                          <template #default>-->
                          <!--                            <video v-else-if="item.FileType.includes('video')" controls="" name="media" width="95%">-->
                          <!--                              <source :src="item.FilePublicUrl + '#t=0.1'" :type="item.FileType">-->
                          <!--                            </video>-->
                        </b-carousel-slide>
                      </b-carousel>

                      <v-container v-else-if="row.Content.ContentTypeId == 4">
                        <h4 class="text-break font-weight-medium">
                          {{ row.Content.Description }}
                        </h4>
                      </v-container>

                      <v-container v-else-if="row.Content.ContentTypeId == 5">
                        <div v-for="item in row.Content.ContentFiles.filter(s => s.FileType.includes('pdf'))" :key="item.Id">
                          <object class="pt-3 px-3" :data="item.FilePublicUrl" type="application/pdf"
                                  width="100%" height="100%">
                            <p class="m-5">PDF görüntülenemiyor. <a :href="item.FilePublicUrl" target="_blank">PDF'i indirmek için tıklayınız.</a></p>
                          </object>
                        </div>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex">
                      <v-icon @click.stop="like(row.Content)" color="red">{{ row.Content.IsLiked ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                      <v-icon v-if="row.Content.HasComment" @click="openComments(row.ContentId)" class="ml-2">mdi-comment-outline</v-icon>
                      <!--                  <v-icon class="ml-3">mdi-bookmark-outline</v-icon>-->
                      <v-spacer></v-spacer>
                      <span>{{ row.Content.CreatedDate | fromNow }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 d-flex justify-content-between" cols="12">
                      <div class="mt-1">
                        <v-icon class="mr-1" small>mdi-shape</v-icon>
                        {{ row.Content.ContentTypeName }}
                      </div>
                      <div>
                        <v-icon v-if="row.Content.TwitterLink != null && row.Content.TwitterLink.length > 0" class="" color="#1DA1F2" @click="openUrl(row.Content.TwitterLink)">mdi-twitter</v-icon>
                        <v-icon v-if="row.Content.FacebookLink != null && row.Content.FacebookLink.length > 0" class="ml-2" color="#3B5998" @click="openUrl(row.Content.FacebookLink)">mdi-facebook
                        </v-icon>
                        <v-icon v-if="row.Content.InstagramLink != null && row.Content.InstagramLink.length > 0" class="ml-2" color="#d62976" @click="openUrl(row.Content.InstagramLink)">
                          mdi-instagram
                        </v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="showComments && row.Content.ShowComments">
                    <v-col cols="12" class="py-0">
                      <v-expand-transition>
                        <v-list two-line tile dense v-if="!loadingComments">
                          <v-list-item v-for="comment in row.Content.Comments" :key="comment.Id">
                            <v-avatar size="28" rounded>
                              <v-img v-if="comment.ImageUrl != null" :src="comment.ImageUrl" contain></v-img>
                              <v-icon v-else>mdi-account-circle</v-icon>
                            </v-avatar>
                            <v-list-item-content class="ml-2">
                              <span> <strong class="mr-1">  {{ comment.UserName }}</strong> {{ comment.Text }}</span>
                              <span class="text-muted"> {{ comment.CreatedDate | formattedDateTime }}</span>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon small v-if="comment.IsDeletable" @click="deleteComment(comment)">
                                <v-icon color="red" small>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-text-field filled rounded class="mx-2 mt-2" clearable @click:append-outer="addComment(row.Content)" @keydown.enter="addComment(row.Content)"
                                        dense hide-details v-model="row.Content.NewComment" append-outer-icon="mdi-send"
                                        placeholder="yorum ekle..."></v-text-field>
                        </v-list>
                      </v-expand-transition>
                    </v-col>
                  </v-row>
                  <v-row class="mx-3">
                    <v-divider class="m-5"></v-divider>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiService from "@/core/services/api.service";
import moment from "moment";
// import pdf from 'vue-pdf'
import {Capacitor} from "@capacitor/core";
import {Media} from "@capacitor-community/media";
import {Device} from "@capacitor/device";

export default {
  props: ['isDashboard'],
  components: {
    // pdf
  },
  data() {
    return {
      loading: false,
      loadingComments: false,
      rows: [],
      totalPages: 0,
      showComments: false,
      timer: null,
      saving: false,
      options: {
        itemsPerPage: 5,
        sortBy: ["Content.CreatedDate"],
        sortDesc: [true],
        page: 1
      }
    }
  },
  created() {
    this.getDataFromApi();
  },
  filters: {
    fromNow: function (date) {
      return moment(date).locale('tr').fromNow();
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post("api/Panel/panel-contents", {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      })
          .then(({data}) => {
            this.rows = [...new Map(data.Results.map(item => [item['ContentId'], item])).values()];
            this.totalPages = data.TotalNumberOfPages;
            this.loading = false;
          })
          .catch(({response}) => {
            ApiService.showError(response);
            this.loading = false;
          });
    },

    sendReport() {
      setTimeout(() => {
        ApiService.showAlert("Şikayetiniz alınmıştır. En kısa sürede incelenecektir.", "success")
      }, 500);
    },

    downloadAll(files) {
      this.saving = true;
      if (Capacitor.isNativePlatform()) {
        files.forEach(file => {
          var options = {path: file.FilePublicUrl}
          Device.getInfo().then((device) => {
            if (device.platform == 'android') {
              Media.getAlbums().then((albums) => {
                const album = albums.albums.find(x => x.name == 'Dijital Okulum');
                if (album != null) {
                  options.albumIdentifier = album.identifier;
                  if (file.FileType.includes('image')) {
                    Media.savePhoto(options).then(() => {
                      ApiService.showAlert("Fotoğraf indirildi.", "success")
                    });
                  } else if (file.FileType.includes('video')) {
                    Media.saveVideo(options).then(() => {
                      ApiService.showAlert("Video indirildi.", "success")
                    });
                  }
                  this.saving = false
                } else {
                  Media.createAlbum({name: 'Dijital Okulum'}).then((album) => {
                    options.albumIdentifier = album.identifier;
                    if (file.FileType.includes('image')) {
                      Media.savePhoto(options).then(() => {
                        ApiService.showAlert("Fotoğraf indirildi.", "success")
                      });
                    } else if (file.FileType.includes('video')) {
                      Media.saveVideo(options).then(() => {
                        ApiService.showAlert("Video indirildi.", "success")
                      });
                    }
                    this.saving = false
                  })
                }
              })
            } else {
              if (file.FileType.includes('image')) {
                Media.savePhoto(options).then(() => {
                  ApiService.showAlert("Fotoğraf indirildi.", "success")
                });
              } else if (file.FileType.includes('video')) {
                Media.saveVideo(options).then(() => {
                  ApiService.showAlert("Video indirildi.", "success")
                });
              }
              this.saving = false
            }
          })
        })
      } else {
        files.sort((a, b) => {
          if (a.FileType.includes('video') && !b.FileType.includes('video')) return -1;
          if (!a.FileType.includes('video') && b.FileType.includes('video')) return 1;
          return 0;
        });

        var urls = files.map(x => x.FilePublicUrl);

        var interval = setInterval(() => {
          if (urls.length == 0) {
            clearInterval(interval);
            this.saving = false
          }

          var url = urls.pop();
          if (url != null)
            this.forceDownload(url, url.substring(url.lastIndexOf('/') + 1, url.length));
        }, 1500, urls);
      }
    },

    forceDownload(url, fileName) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      }
      xhr.send();
    },

    like(content) {
      ApiService.setHeader()
      ApiService.put("api/Content/content-likes/update", {
        ContentId: content.Id,
        IsLiked: !content.IsLiked
      })
          .then(() => {
            this.rows.find(x => x.ContentId == content.Id).Content.IsLiked = !content.IsLiked;
          })
          .catch(({response}) => {
            ApiService.showError(response);
          });
    },

    openComments(contentId, toggle = true) {
      if (this.showComments && toggle) {
        this.showComments = false;
        this.rows.find(x => x.ContentId === contentId).Content.ShowComments = false
        return;
      }

      this.loadingComments = true;
      ApiService.setHeader()
      ApiService.post("api/Content/content-comments", {
        rowsPerPage: -1,
        page: 1,
        query: {ContentId: contentId}
      })
          .then(({data}) => {
            this.rows.find(x => x.ContentId === contentId).Content.Comments = data.Results;
            this.rows.find(x => x.ContentId === contentId).Content.ShowComments = true;
            this.showComments = true;
            this.loadingComments = false;
          })
          .catch(({response}) => {
            ApiService.showError(response);
            this.loadingComments = false;
          });
    },

    delayedscroll() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.handleScroll()
      }, 500)
    },

    handleScroll() {
      if (this.loading || this.totalPages <= this.options.page) return
      this.loading = true;
      this.options.page++;
      ApiService.setHeader();
      ApiService.post("api/Panel/panel-contents", {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      }).then(({data}) => {
        var newRows = [...new Map(data.Results.map(item => [item['ContentId'], item])).values()];

        for (var i = 0; i < newRows.length; i++) {
          if (this.rows.find(x => x.ContentId == newRows[i].ContentId)) continue;
          this.rows.push(newRows[i]);
        }
        this.loading = false;
      }).catch(({response}) => {
        ApiService.showError(response);
        this.loading = false;
      });
    },

    addComment(content) {
      if (content.NewComment == null || content.NewComment == "") return;
      ApiService.setHeader()
      ApiService.put("api/Content/content-comments", {ContentId: content.Id, Text: content.NewComment})
          .then(() => {
            content.NewComment = null;
            this.openComments(content.Id, false);
          })
          .catch(({response}) => {
            ApiService.showError(response);
          });
    },

    deleteComment(comment) {
      ApiService.setHeader()
      ApiService.delete("api/Content/content-comments/" + comment.Id)
          .then(() => {
            this.openComments(comment.ContentId, false);
          })
          .catch(({response}) => {
            ApiService.showError(response);
          });
    },

    openUrl(url) {
      window.open(url, '_blank');
    }
  },
}
</script>

<style scoped>
video {
  width: 100%;
  height: 95%;
}

.v-image__image {
  background-size: 100% 100%;
}
</style>