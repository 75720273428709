<template>
  <div>
    <div class="row" v-if="!$vuetify.breakpoint.mobile">
      <v-spacer></v-spacer>
      <div class="col-md-6 col-xl-4 d-flex justify-content-center" v-if="isCustomer && !isProxy">
        <b-jumbotron bg-variant="success" text-variant="white" class="text-center">
          <span style="font-size: 32px">Hoş Geldiniz</span>
          <hr class="my-3"/>
          <span v-if="!$vuetify.breakpoint.mobile" class="font-size-h4">
          Öğrencilerinizin hesaplarına geçiş yapmak için menüde isminizin yazdığı butona
          tıklayabilirsiniz.
        </span>
          <span v-else class="font-size-h4">
          Öğrencilerinizin hesaplarına geçiş yapmak için alt menüde öğrenciler ikonuna
          tıklayabilirsiniz.
        </span>
        </b-jumbotron>
      </div>
      <v-spacer></v-spacer>
    </div>
    <div class="row" v-if="!isCustomer || isProxy">
      <div class="col-sm align-self-center">
        <b-alert show variant="primary" class="mt-5">HOŞ GELDİN {{ user.FirstName }}</b-alert>
        <StudentExamGoal></StudentExamGoal>
      </div>
    </div>
    <div class="row mt-1" v-if="!isCustomer || isProxy">
      <div class="col-sm align-self-center pt-1">
        <DashboardDateWidget @updateDates="datesUpdated($event)"></DashboardDateWidget>
      </div>
    </div>
    <div class="row mt-1" v-if="(!isCustomer || isProxy) && user && !user.IsElementaryStudent">
      <div class="col-sm align-self-center">
        <HomeworksWidget v-if="dates.length > 0" :dates="dates" :key="compKey"/>
      </div>
    </div>
    <div class="row mt-1" v-if="!isCustomer || isProxy">
      <div class="col-sm align-self-center">
        <ManualHomeworksWidget v-if="dates.length > 0" :dates="dates" :key="compKey"/>
      </div>
    </div>
    <div class="row mt-3" v-if="!isCustomer || isProxy">
      <div class="col-sm align-self-center">
        <Calendar v-if="dates.length > 0" :dates="dates" :key="compKey"/>
        <!-- <LessonTimesTableWidget ref="LessonTimesTableWidgetcomponent" /> -->
      </div>
    </div>
    <!-- dashboarddan oturumlar kaldırıldı -->
    <div v-if="examSessions.length && (!isCustomer || isProxy)" class="row mt-5">
      <div class="col-sm align-self-center">
        <ExamSessionsWidget
            :examSessions="examSessions"
            :loading="loading"
            :title="'Yaklaşan ve Aktif Sınav Oturumlarınız'"
            ref="ExamSessionsWidget"
        />
      </div>
    </div>
    <div v-if="isCustomer && !isProxy" class="row">
      <Panel :is-dashboard="true"></Panel>
    </div>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import HomeworksWidget from '@/view/pages/dashboard_widgets/HomeworksWidget.vue'
import ManualHomeworksWidget from '@/view/pages/dashboard_widgets/ManualHomeworksWidget.vue'
import Calendar from '@/view/pages/dashboard_widgets/Calendar.vue'
import ExamSessionsWidget from '@/view/pages/dashboard_widgets/ExamSessionsWidget.vue'
import {App} from '@capacitor/app'
import {Device} from '@capacitor/device'
import Swal from 'sweetalert2'
import StudentExamGoal from '@/view/pages/dashboard_widgets/StudentExamGoal.vue'
import {Capacitor} from '@capacitor/core'
import {PushNotifications} from '@capacitor/push-notifications'
import DashboardDateWidget from '@/view/pages/dashboard_widgets/DashboardDateWidget'
import Panel from '@/view/pages/social_school/Panel.vue'
import {CHANGE_TOKEN} from "@/core/services/store/auth.module";

export default {
  name: 'dashboard-page',
  props: ['isProxy', 'isCustomer'],
  data() {
    return {
      user: {},
      student: {},
      examSessions: [],
      loading: false,
      dates: [],
      compKey: 0
    }
  },
  components: {
    DashboardDateWidget,
    Calendar,
    HomeworksWidget,
    ExamSessionsWidget,
    StudentExamGoal,
    ManualHomeworksWidget,
    Panel
  },
  async mounted() {
    this.getUserFromApi()
    if (Capacitor.isNativePlatform()) {
      await this.registerToken()
      await this.getAppInfo()
    }

    if (this.$route.query.studentId != null && this.$route.query.studentId > 0)
      this.changeUser(this.$route.query.studentId)
  },
  methods: {
    datesUpdated(dates) {
      this.dates = dates
      this.compKey++
    },

    getUserFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Profile')
          .then((data) => {
            this.user = data.data
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    async getAppInfo() {
      const deviceInfo = await Device.getInfo()

      await App.getInfo().then((app) =>
          ApiService.post('api/App/is-current-version', {ApplicationName: app.id, Platform: deviceInfo.platform, Version: app.version})
              .then(async (data) => {
                if (data.data === false) {
                  await Swal.fire({
                    title: 'Dikkat',
                    text: 'Lütfen uygulamayı son sürüme güncelleyiniz',
                    position: 'center',
                    icon: 'warning',
                    confirmButtonClass: 'btn btn-secondary',
                    heightAuto: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'TAMAM'
                  })
                }
              })
      )
    },

    async registerToken() {
      let permStatus = await PushNotifications.checkPermissions()

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== 'granted') {
        // await Swal.fire({
        //   title: 'Dikkat',
        //   text: 'Lütfen bildirimlere izin veriniz.',
        //   position: 'center',
        //   icon: 'warning',
        //   confirmButtonClass: 'btn btn-secondary',
        //   heightAuto: false,
        //   allowOutsideClick: false,
        //   confirmButtonText: 'TAMAM'
        // })
      } else {
        var token = this.isCustomer
            ? localStorage.getItem('fcmCustomerToken')
            : localStorage.getItem('fcmStudentToken')

        if (token == null || token.length <= 0) {
          await PushNotifications.register()
          await PushNotifications.addListener('registration', async (token) => {
            const deviceId = await Device.getId()

            await ApiService.setHeader()
            await ApiService.put('api/App', {Token: token.value, DeviceId: deviceId.uuid})
                .then(async (data) => {
                  this.isCustomer
                      ? localStorage.setItem('fcmCustomerToken', data.data)
                      : localStorage.setItem('fcmStudentToken', data.data)
                })
                .catch(({response}) => {
                  ApiService.showError(response)
                })
          })
        }
      }
    },

    changeUser(id) {
      ApiService.setHeader()
      ApiService.post('api/customerproxyuser', {
        UserId: id,
        Type: 'Student'
      })
          .then(({data}) => {
            this.$store.dispatch(CHANGE_TOKEN, data)
            window.location.reload()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
  }
}
</script>
